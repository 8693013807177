<template>
  <div class="close-modal-button" @click="closeModal">
    <AppIcon name="x-close" size="16px" :opacity="0.4" is-img-tag />
  </div>
</template>

<script setup>
const emit = defineEmits(['close']);

const closeModal = () => {
  emit('close');
};
</script>

<style scoped lang="scss">
.close-modal-button {
  width: 36px;
  height: 36px;
  position: absolute;
  z-index: 3;
  top: 12px;
  right: 12px;

  padding: 10px;
  cursor: pointer;

  background: rgba(0, 0, 0, 0);
  border-radius: 50%;

  @include transition-base;

  &:hover {
    background: var(--color-black-005);

    :deep(.icon) {
      opacity: 1;
    }
  }
}
</style>
