<template>
  <div class="app-checkbox" :class="[type, size, { enabled: value, focus: focused }]" @click="() => value = !value">
    <div class="visually-hidden">
      <label :for="checkboxId">Checkbox</label>
      <input :id="checkboxId" ref="checkbox" v-model="value" type="checkbox">
    </div>

    <div class="app-checkbox-control">
      <AppIcon :name="icon" size="8px" fill="var(--color-white)" />
    </div>
  </div>
</template>

<script setup>
import { useFocus } from '@vueuse/core';
import { computed, ref } from 'vue';

import { getRandomString } from '@/utils/functions';

const props = defineProps({
  type: {
    type: String,
    default: 'square',
    validator: (value) => ['square', 'round'].includes(value),
  },
  size: {
    type: String,
    default: 'normal',
    validator: (value) => ['normal', 'small'].includes(value),
  },
});

const checkboxId = `AppCheckbox-${getRandomString()}`;

const value = defineModel({ type: Boolean, default: false });

const checkbox = ref();
const { focused } = useFocus(checkbox);

const icon = computed(() => (props.size === 'normal' ? 'check' : 'check-wide'));
</script>

<style lang="scss" scoped>
.app-checkbox {
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  @include flex-center;

  &.round {
    border-radius: 50%;
    width: 22px;
    height: 22px;

    &.focus {
      outline: 2px solid var(--color-black);
    }

    .app-checkbox-control {
      @include flex-center;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      @include transition-base('background-color');

      :deep(.icon) {
        opacity: 0;
      }

      @at-root .enabled#{&} {
        background-color: var(--color-primary);

        :deep(.icon) {
          opacity: 1;
        }
      }

      @at-root :not(.enabled)#{&} {
        border: 2px solid var(--color-black-01);

        &:hover {
          border-color: var(--color-primary-05);
        }
      }
    }

    &.small {
      width: 15px;
      height: 15px;

      .app-checkbox-control {
        width: 14px;
        height: 14px;

        @at-root :not(.enabled)#{&} {
          border: 1px solid var(--color-black-01);
        }
      }
    }
  }
}
</style>
