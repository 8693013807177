<template>
  <Teleport to="body">
    <Transition name="show-fast">
      <div
        v-if="modelValue"
        class="background-overlay"
        @mousedown.self="onClickOutside"
      >
        <div
          ref="modal"
          class="modal"
          :class="{
            'has-border-top': borderTopColor,
          }"
          tabindex="-1"
          :style="`
            width: ${width};
            height: ${height};
            --max-height: ${MAX_HEIGHT}px;
            --border-color: ${borderTopColor};
          `"
        >
          <div
            class="modal__header"
            :class="{
              'has-divider': hasHeaderDivider,
              'is-visible': hasHeader,
              'has-small-header-paddings': hasSmallHeaderPaddings,
            }"
          >
            <AppText
              variant="div"
              size="18px"
              class="font-medium"
              mb="8px"
            >
              <slot name="title">
                {{ title }}
              </slot>
            </AppText>
            <slot v-if="isDescriptionVisible" name="description">
              <AppText
                variant="div"
                size="13px"
                opacity="0.5"
                :line-height="1.35"
              >
                {{ description }}
              </AppText>
            </slot>
          </div>
          <CloseModalButton v-if="hasCloseButton" @close="closeModal" />
          <div
            class="modal__wrapper"
            :class="{
              'no-overflow': noOverflow,
              'is-height-100': !hasTransitionHeight,
            }"
          >
            <div ref="modalContent" class="modal-content">
              <div
                class="modal__body"
                :class="{
                  'no-overflow': noOverflow,
                  'has-paddings': hasBodyPaddings,
                }"
              >
                <slot :height="contentHeight" :has-transition-height="hasTransitionHeight" />
              </div>
              <div
                class="modal__footer"
                :class="{
                  'has-divider': hasFooterDivider,
                  'has-margin': hasFooterMargin,
                }"
              >
                <slot name="footer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import {
  computed, defineComponent, ref, watch,
} from 'vue';

import { emitter, useBus } from '@/composables/useBus';
import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';

import CloseModalButton from './CloseModalButton.vue';
import { trapFocus } from './useTabNavigation';

export default defineComponent({
  name: 'SmoothModal',
  components: {
    CloseModalButton,
  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    description: {
      type: String,
      default: 'Description',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '660px',
    },
    height: {
      type: String,
      default: 'auto',
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    isDescriptionVisible: {
      type: Boolean,
      default: true,
    },
    noOverflow: {
      type: Boolean,
      default: false,
    },
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
    hasSmallHeaderPaddings: {
      type: Boolean,
      default: false,
    },
    hasHeaderDivider: {
      type: Boolean,
      default: true,
    },
    hasFooterDivider: {
      type: Boolean,
      default: false,
    },
    hasFooterMargin: {
      type: Boolean,
      default: false,
    },
    hasBodyPaddings: {
      type: Boolean,
      default: true,
    },
    borderTopColor: {
      type: [String, null],
      default: null,
    },
    noClickOutside: {
      type: Boolean,
      default: false,
    },
    hasTransitionHeight: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'close', 'open'],
  setup(props, { emit }) {
    const modal = ref(null);
    function initTrapFocus(e) {
      return trapFocus(e, modal.value);
    }

    const onClickOutside = () => {
      if (!props.noClickOutside) {
        closeModal();
      }
    };
    const closeModal = () => {
      emit('update:modelValue');
      emit('close');
      document.removeEventListener('keydown', initTrapFocus);
    };

    emitter.on('close-modal', closeModal);

    const { emitHandler } = useBus();
    emitHandler('calculate-height', () => {
      calculateHeight();
    });

    const isVisible = ref(false);

    const modalContent = ref(null);
    const MAX_HEIGHT = 880;
    const contentHeight = ref(MAX_HEIGHT);

    const calculateHeight = () => {
      if (props.modelValue) {
        contentHeight.value = modalContent.value?.clientHeight >= MAX_HEIGHT ? MAX_HEIGHT : modalContent.value?.clientHeight;

        if (modal.value) {
          modal.value.style.setProperty('--contentHeight', `${contentHeight.value}px`);
        }
      }
    };

    watch(computed(() => props.modelValue), (val) => {
      setTimeout(() => {
        if (props.hasTransitionHeight) {
          const resizeObserver = new ResizeObserver((() => { calculateHeight(); }));

          if (props.modelValue) {
            resizeObserver.observe(modalContent.value);
          }
        } else {
          calculateHeight();
        }

        if (val) {
          modal.value.focus();
        }
      }, 0);
      if (val) {
        emit('open');
        document.addEventListener('keydown', initTrapFocus);
      }
    });

    useKeyboardEvent(keys.ESCAPE, closeModal);

    return {
      isVisible,
      closeModal,
      onClickOutside,

      modal,
      modalContent,
      contentHeight,

      MAX_HEIGHT,
    };
  },
});
</script>

<style lang="scss" scoped>
.background-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-black-06);
  display: flex;
  justify-content: center;

  @media screen and (max-height: 1200px) {
    align-items: center;
  }
}

.modal {
  background: var(--color-white);
  position: absolute;
  overflow: hidden;
  border-radius: 6px;
  margin-top: 150px;

  @media screen and (max-height: 1200px) {
    margin-top: unset;
  }

  &:focus-visible {
    outline: transparent !important;
  }
}

.has-border-top {
  border-top: 4px solid var(--border-color);
}

.modal__wrapper {
  transition: all 0.1s cubic-bezier(0, 1.2, 1, 0.97);
  overflow: hidden;

  max-height: var(--contentHeight);
  min-height: var(--contentHeight);

  .modal__body {
    min-height: 120px;
    padding: 0 0 1px;

    max-height: var(--max-height);
    padding-top: 1px;

    &.has-paddings {
      padding: 0 40px 40px;
    }
  }
}

.modal__header {
  padding: 30px 40px 18px 40px;
  position: relative;
  display: none;

  &.has-divider {
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 20px;
  }
  &.is-visible {
    display: block;
    max-height: 200px;
  }

  &.has-small-header-paddings {
    padding: 24px 30px 16px;
    margin-bottom: 10px;
  }
  :deep(.text) {
    width: 100%;
  }
}

.modal__footer {
  margin: 0;
  padding-bottom: 0;
  &.has-divider {
    border-top: 1px solid #E0E0E0;
  }
  &.has-margin {
    margin: 0 40px;
    padding-bottom: 20px;
  }

}

.no-overflow {
  overflow: visible !important;
}

@mixin height-100 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.is-height-100 {
  @include height-100;
  .modal-content, .modal__body {
    @include height-100;
    :deep(.tab-container) {
      @include height-100;
    }
  }
}
</style>
