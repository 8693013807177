<template>
  <MainTable
    class="simple-table"
    :is-loading="isLoading"
    :columns="columns"
    :data="data"
    disable-hover
    disable-border
  >
    <template #column="{ column }">
      <slot name="column" :column="column">
        <AppText size="12px" type="uppercase" class="font-medium">
          {{ column.title }}
        </AppText>
      </slot>
    </template>
    <template #cell="{ row, column, item }">
      <div class="d-flex align-items-center">
        <slot name="main" :row="row" :column="column" :item="item" />
      </div>
    </template>
    <template #nodata>
      <slot name="nodata" />
    </template>
  </MainTable>
</template>

<script>
import { defineComponent } from 'vue';

import MainTable from '@/components/MainTable';

export default defineComponent({
  components: {
    MainTable,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
    };
  },
});
</script>

<style scoped lang="scss">
.simple-table {
  outline: none;
  border-bottom: 1px solid var(--color-black-01);
  :deep(.table) {
    box-shadow: unset;
    .table__head .table__row {
      border-bottom: unset;

      .table__head__column {
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }

      .table__head__column .table__column__cell {
        padding-left: 20px;
      }
    }
    .table__column {
      border-right: unset;
    }
    .table__body .table__row__cell {
      .shield-icon {
        @include flex-center;
        padding: 6px;
        background: var(--color-black-005);
        border-radius: 50%;
        margin-right: 12px;
        margin-left: -10px;
      }

      .flag-icon {
        margin-right: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
</style>
