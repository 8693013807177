export const useTabNavigation = () => {

};

export const trapFocus = (e, modalElement) => {
  /*
    https://codepen.io/zpthree/pen/RwdWNvM?editors=1010
    https://gist.github.com/myogeshchavan97/d50d42aa9205573b811587d57c2e58a6
    https://zachpatrick.com/blog/how-to-trap-focus-inside-modal-to-make-it-ada-compliant
  */
  const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

  if (!isTabPressed) {
    return;
  }

  const focusableElements = 'button, [href], input, select, textarea, iframe, [tabindex]:not([tabindex="-1"])';

  const firstFocusableElement = modalElement.querySelectorAll(focusableElements)[0];
  const focusableContent = modalElement.querySelectorAll(focusableElements);
  const lastFocusableElement = focusableContent[focusableContent.length - 1];

  if (e.shiftKey) {
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus();
      e.preventDefault();
    }
  } else if (document.activeElement === lastFocusableElement) {
    firstFocusableElement.focus();
    e.preventDefault();
  }
};
